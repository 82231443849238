import React from 'react';

import Content from '@components/Layout/Content/Content';
import PageSection from '@components/Layout/PageSection/PageSection';
import Page from '@components/Master/Page';
import SEO from '@components/Master/SEO';
import Copy from '@components/Typography/Copy';
import H from '@components/Typography/H';

const PrivacyPolicyPage = () => {
	return (
		<Page>
			<SEO title="Privacy Policy | UHV Design" />

			<PageSection>
				<Content>
					<H level={2} as="h1">
						Privacy Policy
					</H>
				</Content>

				<Content constrict>
					<Copy>
						The privacy of our customers is of utmost importance to
						UHV Design Ltd. Please read the following policy to
						understand how we will treat your personal information
						when you use our site.
					</Copy>

					<H level={3} as="h2">
						The information we collect
					</H>

					<Copy>
						In order to provide you with the best customer service,
						we collect certain personal information about you. This
						information is typically limited to business
						correspondance. When you register for our Product
						Configurator or sign up to receive occassional emails
						from us, any personalisation is recorded so only
						relevant information comes to you. This information may
						include, but is not limited to, details such as your
						name, your address and other contact details.
					</Copy>

					<H level={3} as="h2">
						What do we do with your personal information?
					</H>

					<Copy>
						We use your personal information to provide you with the
						best online experience and to provide you with a
						personalised service to give you details of offers which
						we think will be of interest to you. If you have chosen
						to receive them, we will also use your information to
						provide you with our mail and email alerts letting you
						stay up-to-date about relevant offers, demonstrations
						and events. We may also use the information to process
						any transactions you undertake with us and for internal
						administration and analysis. We disclose your
						information to third parties only for the purpose of
						completing your transaction with us, providing the above
						services to you and for the purposes of associated
						administration and obtaining professional advice. We do
						not sell, rent or trade your personal information to
						third parties for marketing purposes.
					</Copy>

					<H level={3} as="h2">
						Cookies
					</H>

					<Copy>
						Cookies are small files which are sent to your browser
						and stored on your computer's hard disc. For more
						information about cookies, please visit{' '}
						<a
							href="http://www.aboutcookies.org/"
							target="_blank"
							rel="nofollow"
						>
							http://www.aboutcookies.org/
						</a>
						. We may use the cookies only for the following
						purposes:
					</Copy>

					<H level={4} as="h3">
						Types of Cookies we use
					</H>
					<Copy>
						Third-party cookies used on our website are mainly for
						understanding how the website performs and how you
						interact with our website.
					</Copy>

					<Copy as="ul">
						<li>
							_ga - expiration time: 2 years - Used to distinguish
							users.
						</li>
						<li>
							_gid - expiration time: 24 hours - Used to
							distinguish users.
						</li>
					</Copy>

					<H level={3} as="h2">
						Data Protection
					</H>

					<Copy>
						In the UK we operate and are registered in accordance
						with applicable data protection legislation:
					</Copy>

					<H level={4} as="h3">
						Disclosures
					</H>

					<Copy>
						We may disclose information about you to any of our
						employees, officers, agents, suppliers or subcontractors
						insofar as reasonably necessary for the purposes as set
						out in this privacy policy.
					</Copy>

					<Copy>
						In addition, we may disclose your personal information:
					</Copy>

					<Copy as="ul" listStyle="alphabet">
						<li>
							to the extent that we are required to do so by law;
						</li>

						<li>
							in connection with any legal proceedings or
							prospective legal proceedings;
						</li>

						<li>
							in order to establish, exercise or defend our legal
							rights (including providing information to others
							for the purposes of fraud prevention and reducing
							credit risk);
						</li>

						<li>
							to the purchaser (or prospective purchaser) of any
							business or asset which we are (or are
							contemplating) selling; and
						</li>

						<li>
							to any person who we reasonably believe may apply to
							a court or other competent authority for disclosure
							of that personal information where, in our
							reasonable opinion, such court or authority would be
							reasonably likely to order disclosure of that
							personal information.
						</li>
					</Copy>

					<Copy>
						Except as provided in this privacy policy, we will not
						provide your information to third parties.
					</Copy>

					<H level={3} as="h2">
						International data transfers
					</H>

					<Copy>
						Information that we collect may be stored and processed
						in and transferred between any of the countries in which
						we operate in order to enable us to use the information
						in accordance with this privacy policy.
					</Copy>

					<H level={3} as="h2">
						Security of your personal information
					</H>

					<Copy>
						We will take reasonable technical and organisational
						precautions to prevent the loss, misuse or alteration of
						your personal information.
					</Copy>

					<Copy>
						We will store all the personal information you provide
						on our secure (password- and firewall- protected)
						servers. All electronic transactions you make to or
						receive from us will be encrypted.
					</Copy>

					<Copy>
						Of course, data transmission over the internet is
						inherently insecure, and we cannot guarantee the
						security of data sent over the internet.
					</Copy>

					<Copy>
						You are responsible for keeping your password and user
						details confidential. We will not ask you for your
						password (except when you log in to the website).
					</Copy>

					<H level={3} as="h2">
						Policy amendments
					</H>

					<Copy>
						We may update this privacy policy from time-to-time by
						posting a new version on our website. You should check
						this page occasionally to ensure you are happy with any
						changes.
					</Copy>

					<H level={3} as="h2">
						Your rights
					</H>

					<Copy>
						You may instruct us to provide you with any personal
						information we hold about you. Provision of this
						information will be subject to the supply of appropriate
						evidence of your identity. To request the personal
						information we hold about you, please call us or email
						MyData@uhvdesign.com . We will supply this information
						free of change within 14 days.
					</Copy>

					<Copy>
						You have the right to request that the personal
						information we hold about you is removed from our
						records at any time. Please make the request to
						MyData@uhvdesign.com.
					</Copy>

					<Copy>
						You may instruct us not to process your personal
						information for marketing purposes by email at any time.
						In practice, you will usually either expressly agree in
						advance to our use of your personal information for
						marketing purposes, or we will provide you with an
						opportunity to opt-out of the use of your personal
						information for marketing purposes.
					</Copy>

					<H level={3} as="h2">
						Third party websites
					</H>

					<Copy>
						The website contains links to other websites. We are not
						responsible for the privacy policies or practices of
						third party websites.
					</Copy>

					<H level={3} as="h2">
						Consent
					</H>

					<Copy>
						By disclosing your personal information to us using this
						website or over the telephone, you consent to the
						collection, storage and processing of your personal
						information by UHV Design Ltd in the manner set out in
						this Privacy Policy.
					</Copy>
				</Content>
			</PageSection>
		</Page>
	);
};

export default PrivacyPolicyPage;
